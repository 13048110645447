<template>
  <div v-if="true">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="business-card"
      >
        <b-card-body>
          <b-card-text>
          </b-card-text>
          <b-form>

            <b-row>
              <!-- Field: Establecimientos -->
              <b-col
                cols="12"
              >
                <!-- md="4" -->
                <b-form-group
                  label="Establecimiento"
                  label-for="establecimiento"
                  label-cols-md="4"
                >
                  <!-- multiple -->
                  <v-select
                    v-model="user.establecimiento"
                    placeholder="Selecciona un establecimiento..."
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="establecimientoOptions"
                    :reduce="option => option.value"
                    :disabled="!disabledEstablecimiento"
                    @input="changeEstablecimiento"
                  >
                    <template #option="{ title, insignia }">
                      <b-avatar
                        size="32"
                        :src="insignia"
                        :text="avatarText(title)"
                      />
                      <span> {{ title }}</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Field: Rol -->
              <b-col
                cols="12"
              >
                <!-- md="4" -->
                <b-form-group
                  label="Rol"
                  label-for="rol"
                  label-cols-md="4"
                >
                  <!-- multiple -->
                  <v-select
                    v-model="user.rol"
                    placeholder="Selecciona un rol..."
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="rolOptions"
                    :reduce="option => option.value"
                    :disabled="!disabledRol"
                    @input="changeRol"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Periodo -->
              <b-col
                cols="12"
              >
                <!-- md="4" -->
                <b-form-group
                  label="Periodo"
                  label-for="periodo"
                  label-cols-md="4"
                >
                  <!-- multiple -->
                  <v-select
                    v-model="user.periodo"
                    placeholder="Selecciona un periodo..."
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="periodoOptions"
                    :reduce="option => option.value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardBody, BCardText, BOverlay, BForm, BFormGroup, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BAvatar,

    vSelect,
  },
  data() {
    return {
      spinner: false,
      user: {
        establecimiento: null,
        rol: null,
        periodo: null,
      },
      establecimientoOptions: [],
      rolOptions: [],
      periodoOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getPeriodos: 'periodos/getPeriodos',
    }),
    disabledEstablecimiento() {
      return this.establecimientoOptions.length > 1
    },
    disabledRol() {
      return this.rolOptions.length > 1
    },
  },
  mounted() {
    this.setEstablecimientos(this.getUser)
    this.setRoles(this.getUser)
    this.setPeriodos(this.getUser)
  },
  methods: {
    ...mapActions({
      updateUsuariosVistas: 'usuarios/updateUsuariosVistas',
      fetchAttempt: 'auth/attempt',
      fetchPeriodos: 'periodos/fetchPeriodos',
    }),
    getEstablecimientoActivo(establecimientos, idEstablecimientoActivo) {
      return establecimientos.find(
        es => es.idEstablecimiento === idEstablecimientoActivo,
      )
    },
    setEstablecimientos(usuario) {
      const { idEstablecimientoActivo, establecimientos } = usuario
      this.user.establecimiento = idEstablecimientoActivo
      establecimientos.forEach(establecimiento => {
        this.establecimientoOptions.push({
          value: establecimiento.idEstablecimiento,
          title: establecimiento.nombreEstablecimiento,
          insignia: establecimiento.insignia,
        })
      })
    },
    setRoles(usuario) {
      const {
        rolActivo,
        establecimientos,
        idEstablecimientoActivo,
      } = usuario
      const establecimientoActivo = this.getEstablecimientoActivo(
        establecimientos,
        idEstablecimientoActivo,
      )
      const { roles } = establecimientoActivo
      roles.forEach(rol => {
        this.rolOptions.push({
          value: rol.nombre,
          title: rol.nombre,
        })
      })
      this.user.rol = rolActivo
    },
    setPeriodos(usuario) {
      const {
        // idPeriodoActivo,
        establecimientos,
        idEstablecimientoActivo,
      } = usuario
      const establecimientoActivo = this.getEstablecimientoActivo(
        establecimientos,
        idEstablecimientoActivo,
      )
      this.fetchPeriodos().then(() => {
        this.periodoOptions = []
        this.getPeriodos.forEach(periodo => {
          const title = establecimientoActivo.idPeriodoActivo === periodo.id
            ? `${periodo.nombre} - Activo`
            : periodo.nombre
          this.periodoOptions.push({
            value: periodo.id,
            title,
          })
        })
      })
      this.user.periodo = establecimientoActivo.idPeriodoActivo
    },
    changeEstablecimiento() {
      this.$swal({
        title: 'Atención!',
        text: `La información de todo el sistema, será la correspondiente al
          Establecimiento actualizado.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.editarUsuario(this.user)
        }
      })
      // editar rol activo y periodo activo
    },
    changeRol() {
      this.$swal({
        title: 'Cambio de Rol!',
        text: `Los accesos de todo el sistema, serán los correspondientes al
          Rol actualizado.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.editarUsuario(this.user)
        }
      })
    },
    editarUsuario(usuario) {
      const data = {
        idUsuario: this.getUser.id,
        establecimiento: usuario.establecimiento,
        rol: usuario.rol,
        periodo: usuario.periodo,
      }

      this.updateUsuariosVistas(data).then(() => {
        const errorUsuarios = store.state.usuarios
        const errorMessage = errorUsuarios.errorMessage.errors
        if (!errorUsuarios.error) {
          this.fetchAttempt().then(() => {
            this.login()
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorUsuarios.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
      // refresh la página
    },
    login() {
      localStorage.setItem('userData', JSON.stringify(this.getUser))
      this.$ability.update(this.getUser.ability)
      const {
        rolActivo,
        idEstablecimientoActivo,
        establecimientos,
      } = this.getUser
      const establecimientoActivo = this.getEstablecimientoActivo(
        establecimientos,
        idEstablecimientoActivo,
      )
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Sesión actualizada correctamente',
          icon: 'CoffeeIcon',
          variant: 'primary',
          text: `Ahora puedes comenzar a explorar como
            ${rolActivo} en ${establecimientoActivo.nombreEstablecimiento}! 👋`,
        },
      },
      {
        timeout: 2000,
      })
      this.$router.replace({
        name: 'home',
      })
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.establecimiento.insignia = base64
    })

    return {
      avatarText,
      previewEl,
      refInputEl,
      inputImageRenderer,
    }
  },
}
</script>
