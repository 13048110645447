<template>
  <div v-if="true">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="business-card"
      >
        <b-card-body>
          <b-card-text>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardBody, BCardText, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
// import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    // ...mapState('alarmas', ['alarmas']),
    ...mapGetters({
      getUser: 'auth/user',
    }),
  },
  mounted() {
  },
  methods: {
    // ...mapActions({
    // }),
  },
}
</script>
