<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- ajustesDelSistemaAdmin tab ADMINS -->
    <b-tab
      v-if="user.rolActivo === 'Super Administrador'
        || user.rolActivo === 'Administrador Daem'"
      active
    >

      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ajustes del sistema</span>
      </template>

      <ajustesDelSistemaAdmin />

    </b-tab>

    <!-- ajustesDelSistema tab -->
    <b-tab v-else active>

      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ajustes del sistema</span>
      </template>

      <ajustesDelSistema />

    </b-tab>

    <!-- change password tab -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cambiar Contraseña</span>
      </template>

      <cambiarPassword />

    </b-tab> -->

    <!-- info -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Alarmas</span>
      </template>

      <alarmas />

    </b-tab> -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import ajustesDelSistema from './components/AjustesDelSistema.vue'
import ajustesDelSistemaAdmin from './components/AjustesDelSistemaAdmin.vue'
// import cambiarPassword from './components/CambiarPass.vue'

export default {
  components: {
    BTabs,
    BTab,

    ajustesDelSistema,
    ajustesDelSistemaAdmin,
    // cambiarPassword,
  },
  data() {
    return {
      options: {},
      userData: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getUsuarios: 'usuarios/getUsuarios',
    }),
  },
  watch: {
    getUsuarios() {
      this.userData = this.getUsuarios.find(u => u.id === this.user.id)
    },
  },
}
</script>
